@use "../../sass/variables" as *;

.message {
	@apply p-2 text-sm whitespace-pre-line rounded flex gap-[10px] items-center;
	border-left: 5px solid;
	transition: opacity 0.2s ease-in-out;
	&:empty {
		display: none;
	}
	i {
		height: 20px;
		width: 20px;
		font-size: 1.25rem;
	}
	&__error {
		@extend .message;
		@apply border-red-600 bg-red-100 text-red-600;
	}
	&__note {
		@extend .message;
		@apply border-gold bg-slate-100 text-slate-800;
	}
}
