@use "../../sass/variables" as *;

.sidebar {
  height: calc(100vh - #{$topbar-height});
  position: fixed;
  top: $topbar-height;
  color: $slate-50;
  background-color: $slate-900;
  &__logo span i,
  &__control span i {
    font-size: 1.6rem;
  }
  &__content {
    height: 100%;
  }
  &__control {
    i {
      width: $sidebar-icon-size;
      height: $sidebar-icon-size;
      border-radius: 50%;
      background-color: $green-500;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      &:before {
        font-size: $sidebar-icon-size;
        line-height: 1;
      }
    }
  }
  &--open {
    width: $sidebar-width-open;
  }
  &--closed {
    width: $sidebar-width-closed;
  }
}

.nav {
  &__list {
    height: 100%;
  }
  &__separator {
    padding: 0 5px;
    border: 1px solid $slate-800;
  }
  &__link {
    width: 100%;
    padding: calc(($sidebar-width-closed - $sidebar-icon-size - 3px) / 2);
    border-left: 3px solid rgba($green-500, 0);
    color: $slate-200;
    display: flex;
    cursor: pointer;
    column-gap: $space-sm;
    white-space: nowrap;
    &:hover:not(.active) {
      background: rgba($slate-500, 0.1);
    }
    &.active {
      background-color: $slate-800;
      border-left: 3px solid rgba($green-500, 0.9);
    }
    i {
      width: $sidebar-icon-size;
      height: $sidebar-icon-size;
      @apply text-secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      &:before {
        font-size: $sidebar-icon-size;
        line-height: 1;
      }
    }
    span {
      transition: none;
    }
  }
  &__button {
    width: 100%;
    padding: calc(($sidebar-width-closed - $sidebar-icon-size - 3px) / 2);
    border-left: 3px solid rgba($green-500, 0);
    background-color: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    column-gap: $space-sm;
  }
}
