@use "../../sass/variables" as *;

.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: $color-primary;
  animation: spin 1.2s linear infinite;
  will-change: transform;
  &-inline {
    height: 1rem;
    width: 1rem;
    border-width: 2px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
