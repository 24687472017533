@use "../../sass/variables" as *;

.review-form {
	.select-container {
		border: none;
		&[readonly] {
			background-color: inherit;
			.btn-select {
				padding: 0;
			}
			.btn-select div:first-of-type:not(:empty) {
				padding: 2px 0.5em;
				margin-top: 2px;
				border-radius: $border-radius;
				background-color: $slate-300;
				color: #fff;
				font-weight: 600;
				white-space: nowrap;
				position: relative;
				bottom: 2px;
			}
			// well-done
			&.select-value__1 {
				.btn-select div:first-of-type:not(empty) {
					background-color: $green-500;
				}
			}
			// sufficient
			&.select-value__2 {
				.btn-select div:first-of-type:not(empty) {
					background-color: $sky-400;
				}
			}
			// insufficient
			&.select-value__3 {
				.btn-select div:first-of-type:not(empty) {
					background-color: $red-600;
				}
			}
		}
		.btn-select {
			padding-top: 0.45rem;
			padding-bottom: 0.45rem;
		}
	}
}
