@use "sass:color";

@use "../../../sass/variables" as *;

.btn {
  min-width: 8rem;
  padding: 6px 11px 7px;
  @apply border rounded border-slate-400;
  display: inline-flex;
  gap: 0.2rem;
  align-items: center;
  outline: none;
  color: $black;
  font-size: 0.95rem;
  line-height: normal;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  &:focus-within {
    outline: 2px solid $color-primary;
    outline-offset: 1px;
  }
  &:hover {
    background-color: color.adjust($slate-100, $lightness: -2%);
  }
  &.disabled {
    pointer-events: none;
    background-color: $slate-300;
    color: $slate-400;
    font-weight: 400;
  }
  &-primary {
    padding: 7px 12px 8px;
    @apply border-0;
    background-color: $color-primary;
    color: $white;
    font-weight: 500;
    &:hover {
      background-color: color.adjust($color-primary, $lightness: -4%);
    }
  }
  &-secondary {
    background-color: $color-secondary;
    color: $white;
    font-weight: 500;
    &:hover {
      background-color: color.adjust($color-secondary, $lightness: -4%);
    }
  }
  &-delete {
    padding: 7px 12px 8px;
    @apply border-0;
    background-color: $red-600;
    color: $white;
    font-weight: 500;
    &:not(:disabled):hover {
      background-color: color.adjust($red-600, $lightness: -4%);
    }
  }
  &-close {
    min-width: auto;
    padding: 0;
    border: none;
    position: absolute;
    right: 6px;
    top: 6px;
    background-color: inherit;
    font-size: 32px;
    cursor: pointer;
    &:focus-within {
      border-radius: $border-radius;
      outline: 2px solid $color-primary;
    }
    &:hover {
      background-color: transparent;
      opacity: 0.75;
    }
  }
  &-back {
    min-width: auto;
    padding: 0 2px;
    border: none;
    background-color: inherit;
    font-size: 24px;
    font-weight: 300;
    color: $color-primary;
    cursor: pointer;
    &:focus-within {
      border-radius: $border-radius;
      outline: 2px solid $color-primary;
    }
    &:hover {
      background-color: transparent;
      opacity: 0.75;
    }
  }
  &-action {
    min-width: 32px;
    height: 32px;
    padding: 4px;
    margin-right: 0;
    border: none;
    display: inline-block;
    background-color: transparent;
    font-weight: 500;
    &:not([disabled]):hover {
      // background-color: transparent;
      @apply text-black bg-slate-300 opacity-75 transition;
    }
    &.disabled {
      background-color: transparent;
      @apply text-slate-500;
      opacity: 0.5;
    }
  }

  &.quicklink {
    width: fit-content;
    color: inherit;
    text-decoration: none;
    border: 1px solid $slate-300;
    border-radius: $border-radius;
    &:hover {
      @apply bg-slate-100;
    }
    &.inactive {
      pointer-events: none;
      background-color: color.adjust($color-inactive, $lightness: 40%);
      color: $color-inactive;
      i {
        color: $color-inactive;
      }
    }
    i {
      width: 24px;
      height: 24px;
      color: $color-secondary;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      &:before {
        font-size: 24px;
      }
    }
  }
}

.btn.inline-action {
  min-width: auto;
  padding: 0 4px;
  color: inherit;
  background: none;
  border: none;
  &:hover i {
    @apply text-gray-500;
  }
  &:hover:not([disabled]) {
    @apply text-slate-600;
  }
  &.danger:not([disabled]):hover {
    @apply text-red-500;
    i {
      color: inherit;
    }
  }
}
