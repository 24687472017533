/* color variables  */
$white: #fff;
$black: #0f172a;

$slate-50: #f8fafc;
$slate-100: #f1f5f9;
$slate-200: #e2e8f0;
$slate-300: #cbd5e1;
$slate-400: #737e8d;
$slate-500: #64748b;
$slate-600: #475569;
$slate-800: #1e293b;
$slate-900: #0f172a;
$red-400: #fc8181;
$red-600: #dc2626;
$green-200: #baf5c1;
$green-300: #3cce71;
$green-400: #38c56b;
$green-500: #1db655;
$cyan-50: #ecfeff;
$sky-100: #d1eafa;
$sky-300: #a6ddff;
$sky-400: #29a4d8;
$sky-600: rgb(2 132 199);
$sky-700: #045986;

$aletta-400: #1e90ff;
$aletta-500: #1770c6;
$aletta-900: #0f172a;

$color-primary: $aletta-400;
$color-primary: $sky-400;
$color-primary: $sky-600;
$color-secondary: $green-400;
$color-secondary: $aletta-500;
$color-error: $red-600;
$color-valid: $green-500;
$color-inactive: $slate-400;
$color-focus: $slate-400;
$color-select: $slate-200;

$text-black: $slate-800;

/* spacing variables */
$space-sm: 0.75rem;
$space: 1rem;
$space-md: 1.25rem;
$space-l: 1.5rem;
$space-xl: 3rem;

/*border-radius*/
$border-radius: 4px;

/* transition variables  */
$transition: all 0.1s ease;

$font-xxs: 0.65rem;
$font-xs: 0.85rem;
$font-sm: 0.95rem;
$font-base: 1rem;
$line-height: 1.45;

/* layout */
$topbar-height: 32px;

$sidebar-width-open: 240px;
$sidebar-width-closed: 64px;

$sidebar-icon-size: 24px;
