@use "./variables" as *;

:root {
	--toastify-font-family: "ubuntu", sans-serif;
	--toastify-color-success: #{$green-500};
}

.Toastify {
	&__toast {
		border-radius: 0;
		&-body {
			color: $text-black;
			line-height: $line-height;
			font-size: $font-sm;
		}
	}
	&__close-button {
		opacity: 1;
		align-self: center;
	}
	&__progress-bar {
		height: 3px;
	}

	.slide-top {
		-webkit-animation: slide-top 0.2s ease-in-out reverse both;
		animation: slide-top 0.2s ease-in-out reverse both;
	}
	.slide-top-hide {
		-webkit-animation: slide-top 0.2s ease-in-out both;
		animation: slide-top 0.2s ease-in-out both;
	}

	@-webkit-keyframes slide-top {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-100px);
			transform: translateY(-100px);
		}
	}
	@keyframes slide-top {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-100px);
			transform: translateY(-100px);
		}
	}
	@-webkit-keyframes slide-top-hide {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-100px);
			transform: translateY(-100px);
		}
	}
	@keyframes slide-top-hide {
		0% {
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		100% {
			-webkit-transform: translateY(-100px);
			transform: translateY(-100px);
		}
	}
}
