@use "../../../sass/variables" as *;

.combo-container {
  min-width: 12ch;
  position: relative;
  border: 1px solid $slate-300;
  border-radius: $border-radius;
  background-color: $white;
  line-height: normal;
  &:focus-within label,
  label.filled {
    max-width: initial;
    overflow: visible;
    padding: 0 4px;
    transform: translate(-4px, calc(-#{$space} - 1rem + 10px));
    font-size: 85%;
    background-color: inherit;
  }
  label {
    max-width: calc(100% - 36px);
    overflow: hidden;
    position: absolute;
    top: calc($space - 4px);
    left: $space-sm;
    transition: $transition;
    border-radius: $border-radius;
    color: $slate-400;
    pointer-events: none;
  }
  input {
    width: 100%;
    height: 100%;
    padding: 0.75rem calc(0.75rem / 2) calc(0.75rem - 4px) 0.75rem;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    font-size: 1rem;
    &[readonly] {
      background-color: $slate-100;
    }
  }

  .btn-combo {
    height: 100%;
    padding: $space-sm calc($space-sm / 2) calc($space-sm - 3px);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    cursor: pointer;
    &[readonly] {
      background-color: $slate-100;
      pointer-events: none;
      i {
        color: $slate-300;
      }
    }
  }
  .btn-clear {
    position: absolute;
    padding: $space-sm;
    background-color: transparent;
    cursor: pointer;
  }
  ul {
    width: 100%;
    max-height: 14.5em;
    position: absolute;
    z-index: 2;
    top: 100%;
    border: 1px solid $slate-300;
    border-radius: $border-radius;
    background-color: white;
    cursor: pointer;
    overflow: scroll;
    li {
      padding: $space-sm;
      white-space: nowrap;
      display: flex;
      &[data-headlessui-state="active"],
      &[data-headlessui-state="active selected"] {
        background-color: $color-select;
      }
    }
  }
  & select {
    appearance: none;
    outline: 0;
    background-image: none;

    padding: $space;
    cursor: pointer;
    border: 1.8px solid rgba($color-primary, 0.3);
    border-radius: $border-radius;
  }
  & .custom-arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: block;

    height: 100%;
    width: 2.5em;
    pointer-events: none;
    border: 1.5px solid rgba($color-primary, 0.2);
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-top: 0.5rem solid $sky-700;
      top: 50%;
    }
  }
}
