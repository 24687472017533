@use "../../sass/variables" as *;

.search-wrapper {
  min-height: min-content;
}

.table,
.inline-table {
  th {
    i {
      font-size: inherit;
      margin-left: 4px;
      color: $slate-400;
    }
    &.sort-asc i,
    &.sort-desc i {
      color: inherit;
    }
  }
  tbody tr {
    /*&:nth-child(even) {
      background-color: $slate-100;
    }*/
    &.selected {
      background-color: $green-200;
    }
    &.selectable {
      cursor: pointer;
    }
  }

  .min-cell-width {
    width: 1%;
    white-space: nowrap;
  }
}
