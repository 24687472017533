@use "../../sass/variables" as *;

.badge {
  padding: 0.125em 0.5em;
  margin-top: 2px;
  border-radius: 5px;
  background-color: $slate-600;
  color: $white;
  font-size: inherit;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  // bottom: -1px;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
  &-outline {
    padding: 1px 6px;
    background-color: transparent;
    border: 1px solid $slate-600;
    color: $slate-600;
  }
}

.badge-value {
  /* single session status */
  &__draft {
    background-color: $slate-400;
  }
  &__submitted {
    @apply bg-gold;
  }
  &__reviewed {
    background-color: $green-500;
  }

  /* class type */
  &__class-study,
  &__self-study {
    background-color: transparent;
    color: $slate-400;
    border: 1px solid $slate-400;
  }

  /* class status */
  &__published {
    @apply bg-secondary;
  }
  &__not-published {
    @apply bg-gold;
  }

  /* grading */
  &__insufficient {
    background-color: $red-600;
  }
  &__sufficient {
    background-color: $sky-400;
  }
  &__well-done {
    background-color: $green-500;
  }
}
