@use "./variables" as *;

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "ubuntu", sans-serif;
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  box-sizing: border-box;
  overflow: auto;
  font-size: 100%;
  scroll-padding-top: 3rem;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: $slate-200;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $slate-400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $slate-500;
}

body {
  color: $slate-800;
  font-size: 1rem;
}

a {
  color: unset;
  text-decoration: underline;
}

button {
  font-size: 100%;
  &[disabled] {
    opacity: 0.5;
  }
}

h1,
h2,
h3 {
  line-height: normal;
}

h1 {
  @apply text-2xl min-h-[2rem] font-bold;
}

h2 {
  margin: 2rem 0 0.8rem;
  font-size: 1.2rem;
  @apply font-medium;
}

h3 {
  font-size: 1.1rem;
  @apply font-medium;
}

img {
  width: 100%;
  height: auto;
}

nav a {
  text-decoration: none;
}

p {
  line-height: $line-height;
}

select {
  color: $slate-400;
}

strong {
  font-weight: 500;
}

table {
  th,
  td {
    padding: $space-sm $space-sm calc($space-sm - 2px);
    font-size: 0.95rem;
    font-weight: 400;
  }
  tbody td {
    height: calc(32px + 2 * #{$space-sm});
  }
}

textarea {
  line-height: 1.45;
}

ul {
  list-style: none;
}

.error {
  font-size: $font-xs;
  color: $color-error;
}

.valid {
  font-size: $font-xs;
  color: $color-valid;
}

.input-container,
.date-input-container .outline-wrapper,
.combo-container,
.select-container,
.textarea-container {
  //&:not([readonly]):focus-within {
  &:focus-within {
    outline: 2px solid $color-focus;
    outline-offset: -1px;
  }
  &[readonly] {
    background-color: $slate-100;
  }
}

.description {
  @apply text-xs text-slate-500 leading-tight;
}

.nav__link,
.nav__button {
  &:focus-within {
    outline: 2px solid $color-primary;
    outline-offset: -2px;
  }
}

a:not(.nav__link):focus-within {
  outline: 2px solid $color-primary;
  outline-offset: 1px;
}
